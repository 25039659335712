@use './esp' as t;
@use './utils' as utils;

@mixin cos-theme-admin {
  @include t.cos-theme-esp-font; // Take font from ESP+ light theme
  @include t.cos-theme-esp-base; // Take base from ESP+ light theme
  @include cos-theme-admin-base; // Apply changed to base
  @include utils.cos-theme-rgb-variables; // Set RGB variables
  @include cos-theme-admin-overrides;
}

@mixin cos-theme-admin-classes {
  @include utils.cos-theme-classes('admin');
}

@mixin cos-theme-admin-base {
  // Primary
  --rgb-primary-100: 238 242 246; // #EEF2F6
  --rgb-primary-200: 140 168 193; // #8CA8C1
  --rgb-primary-300: 77 111 143; // #4D6F8F
  --rgb-primary-400: 51 73 94; // #33495E
  --rgb-primary-500: 37 54 70; // #253646
  --rgb-primary-600: 35 50 64; // #233240
  --rgb-primary-700: 29 42 54; // #1D2A36
  --rgb-primary-800: 20 29 38; // #141D26
  --rgb-primary-900: 9 13 17; // #090D11
  --rgb-primary-content-low: var(--rgb-primary-600);
  --rgb-primary-content-high: 255 255 255; // #FFFFFF

  // Secondary
  --rgb-secondary-100: 234 243 251; // #EAF3FB
  --rgb-secondary-200: 116 178 226; // #74B2E2
  --rgb-secondary-300: 41 127 194; // #297FC2
  --rgb-secondary-400: 29 89 135; // #1D5987
  --rgb-secondary-500: 22 69 105; // #164569
  --rgb-secondary-600: 21 64 97; // #154061
  --rgb-secondary-700: 17 53 80; // #113550
  --rgb-secondary-800: 12 36 55; // #0C2437
  --rgb-secondary-900: 4 14 21; // #040E15
  --rgb-secondary-content-low: var(--rgb-secondary-600);
  --rgb-secondary-content-high: 255 255 255; // #FFFFFF

  // Accent
  --rgb-accent-100: 238 242 246; // #EEF2F6
  --rgb-accent-200: 140 168 193; // #8CA8C1
  --rgb-accent-300: 77 111 143; // #4D6F8F
  --rgb-accent-400: 51 73 94; // #33495E
  --rgb-accent-500: 37 54 70; // #253646
  --rgb-accent-600: 35 50 64; // #233240
  --rgb-accent-700: 29 42 54; // #1D2A36
  --rgb-accent-800: 20 29 38; // #141D26
  --rgb-accent-900: 9 13 17; // #090D11
  --rgb-accent-content-low: var(--rgb-accent-600);
  --rgb-accent-content-high: 255 255 255; // #FFFFFF
}

/* TODO(caio): Check if we really need to have sizes as variables */
/* TODO(caio): Check if color changes are acceptable */
@mixin cos-theme-admin-overrides {
  /* Common */
  --util__border-color: var(--base-200);

  /* Sidebar */
  --sidebar__width: 240px;
  --sidebar__bg: var(--primary-500);

  --sidebar__md__width: 160px;
  --sidebar__sm__width: 70px;

  /* Sidebar - Logo */
  --sidebar__logo-lg__height: 22px;
  --sidebar__logo-sm__height: 24px;
  --sidebar__logo__color: var(--primary-content-high);

  /* Sidebar - Items */
  --sidebar__item__font-size: 15px;
  --sidebar__item__padding-x: 20px;
  --sidebar__item__padding-y: 14px;
  --sidebar__item__color: var(--primary-content-high);
  --sidebar__item__hover__color: var(--primary-content-high);
  --sidebar__item__hover__bg: var(--primary-400);
  --sidebar__item__active__color: var(--primary-content-high);
  --sidebar__item__active__bg: var(--primary-400);

  /* Topbar */
  --topbar__bg: var(--base-100);
  --topbar__height: 70px;
}
