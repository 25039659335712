@use '../settings' as s;

.cos-loading-spinner {
  position: relative;

  &::after {
    content: '';
    z-index: 3;
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 1.25rem;
    height: 1.25rem;
    margin-top: -0.625rem;
    margin-left: -0.625rem;
    border-radius: 50%;
    border: 2px solid var(--base-100);
    border-top-color: var(--base-content-low);

    animation: spinner 0.8s linear infinite;
  }

  &-with-bg {
    width: 100%;
    height: 100%;

    &::before {
      @apply absolute;
      content: '';
      background: rgba(0, 0, 0, 10%);
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      z-index: 2;
    }
  }

  &-lg {
    &::after {
      border-width: 3px;
      width: 2.5rem;
      height: 2.5rem;
      margin-top: -1.25rem;
      margin-left: -1.25rem;
    }
  }
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}
