@use '../settings' as s;

// TODO(caio): This can be moved to the dialog component

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.cdk-overlay-custom-backdrop {
  @apply flex justify-center items-center;

  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
  animation: fadeIn 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
}

.cos-dialog-custom-placeholder {
  background-color: var(--base-100);
  border-radius: theme('borderRadius.DEFAULT');
  transition: width 100ms cubic-bezier(0.25, 0.8, 0.25, 1);
  transition: height 100ms cubic-bezier(0.25, 0.8, 0.25, 1);
  animation: fadeIn 400ms cubic-bezier(0.25, 0.8, 0.25, 1);

  @include s.wider-than-or-equal(theme('screens.lg')) {
    max-height: 80vh !important;
  }
}

.cos-dialog-disable-transform .cos-dialog-container {
  transform: none !important;
}

.cdk-overlay-dark-backdrop,
.cdk-overlay-custom-backdrop {
  background: rgba(0, 0, 0, 75%) !important;
}

.cdk-global-overlay-wrapper:has(cos-dialog) {
  // avoid content jumps that may occur sometimes
  justify-content: center;
  align-items: center;
}

.cdk-overlay-pane.cos-dialog-overlay-pane {
  &.cos-dialog-full-screen {
    border-radius: 0px !important;

    .cos-dialog-container {
      border-radius: 0px !important;

      .cos-modal-close {
        color: var(--base-content-low);
        z-index: 1;
        top: 16px !important;
        right: 16px !important;
      }

      .cos-dialog-content.content-medium {
        padding: 1rem;
      }
    }
  }

  &:not(.with-width) {
    max-width: 100vw;

    @include s.wider-than-or-equal(theme('screens.lg')) {
      max-width: 50rem;
    }
  }

  @include s.wider-than-or-equal(theme('screens.lg')) {
    border-radius: theme('borderRadius.DEFAULT');
    width: auto;
    height: auto !important;
    max-height: 80vh !important;

    &.cos-dialog-full-height {
      max-height: 95vh !important;
    }

    &.cos-dialog-four-fifths-height {
      max-height: 80%;
      min-height: 80%;
    }
  }
}

cos-dialog {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.cos-dialog-container {
  &:empty {
    padding-top: 0 !important;
  }

  height: auto !important;
  padding: 0 !important;
  overflow: hidden !important;
  position: relative;
  width: 100%;

  background: var(--base-200) !important;

  border-radius: theme('borderRadius.DEFAULT');
  box-shadow: theme('boxShadow.menu');

  .cos-dialog__header {
    padding: theme('spacing.4');
    flex-grow: 0;
    display: block;

    :last-child {
      margin: 0;
    }
  }

  > * {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }

  .cos-modal-close {
    padding: theme('spacing.1');
    line-height: 1;
    appearance: none;
    background: none;
    border: none;
    font-size: theme('fontSize.16');
    border-radius: 0;
    position: fixed;
    top: theme('spacing.4');
    right: theme('spacing.4');
    color: var(--secondary-500);
    // https://asicentral.atlassian.net/browse/ENCORE-33987 => https://stackoverflow.com/a/49264494
    transform: translate3d(0, 0, 0);

    @include s.wider-than-or-equal(theme('screens.lg')) {
      font-size: theme('fontSize.24');
      top: theme('spacing.6');
      right: theme('spacing.6');
      color: var(--base-100);
    }
  }

  .cos-dialog-title {
    @apply text-header-lg;
    margin: 0 theme('spacing.5') theme('spacing.1') 0 !important;
    flex: auto;
    flex-grow: 0;
  }

  .cos-dialog-content {
    display: block;
    flex-grow: 1;
    margin: 0 !important;
    max-height: 100%;
    overflow: auto;

    &:not(.empty-header):not(:empty) {
      border-top: 1px solid var(--base-300);
    }

    @include s.wider-than-or-equal(theme('screens.lg')) {
      max-height: 80vh;
    }

    &.content-wide {
      padding: theme('spacing.4');
    }

    &.content-medium {
      padding: theme('spacing.4') theme('spacing.12');
    }

    &.content-layout {
      padding: 0;
    }
  }

  .cos-dialog-actions {
    &:empty {
      padding-top: 0 !important;
      border: none;
    }

    display: flex;
    flex-wrap: wrap;
    align-items: center;
    flex-grow: 0;
    background: var(--base-200) !important;
    border-top: 1px solid var(--base-300);
    min-height: auto !important;
    margin-bottom: 0 !important;
    padding: theme('spacing.4');
    box-sizing: border-box;
  }

  cos-confirm-dialog {
    .cos-dialog-content {
      padding-bottom: theme('spacing.4');
    }

    .cos-dialog__header {
      border-bottom: none;
    }
  }
}

@include s.narrower-than(theme('screens.lg')) {
  .cdk-global-overlay-wrapper {
    .cdk-overlay-pane.cos-dialog-overlay-pane {
      min-width: 100vw !important;

      left: 0 !important;
      right: 0 !important;
      top: 0 !important;
      bottom: 0 !important;
      position: absolute !important;

      .cos-dialog-container {
        border-radius: 0px !important;
      }
    }

    .cos-dialog-container {
      header {
        @apply px-safe-offset-4 pt-safe-offset-4 pb-4;
      }

      .cos-modal-close {
        @apply top-safe-offset-4 right-safe-offset-4;

        color: var(--base-content-low);
        z-index: 1;
      }

      .cos-dialog-actions {
        @apply px-safe-offset-4 pb-safe-offset-4;
      }

      .cos-dialog-content {
        @apply px-safe;
      }

      .cos-dialog-content.content-wide {
        @apply px-safe-offset-4;
      }

      .cos-dialog-content.content-medium {
        @apply py-4 px-safe-offset-4;
      }

      .cos-dialog-content.content-layout {
        @apply px-safe;
      }
    }
  }
}
