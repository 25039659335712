@use '../functions' as f;

@mixin large-input-placeholder {
  font-family: var(--font-family-secondary);
  color: var(--util__large-input-placeholder__color, var(--base-400));
  font-size: theme('fontSize.16');
  font-weight: theme('fontWeight.normal');
  letter-spacing: -0.2px;
  line-height: 1.125rem;
}

@mixin large-input-filled {
  font-family: var(--font-family-secondary);
  color: var(--util__large-input-filled__color, var(--base-content-low));
  font-size: theme('fontSize.16');
  font-weight: theme('fontWeight.normal');
  letter-spacing: -0.2px;
  line-height: 1.125rem;
}

@mixin medium-input-placeholder {
  font-family: var(--font-family-secondary);
  color: var(--util__medium-input-placeholder__color, var(--base-400));
  font-size: theme('fontSize.14');
  font-weight: theme('fontWeight.normal');
  letter-spacing: -0.2px;
  line-height: theme('lineHeight.4');
}

@mixin medium-input-filled {
  font-family: var(--font-family-secondary);
  color: var(--util__medium-input-filled__color, var(--base-content-low));
  font-size: theme('fontSize.14');
  font-weight: theme('fontWeight.normal');
  letter-spacing: -0.2px;
  line-height: theme('lineHeight.4');
}

@mixin small-input-placeholder {
  font-family: var(--font-family-secondary);
  color: var(--util__small-input-placeholder__color, var(--base-400));
  font-size: theme('fontSize.12');
  font-weight: theme('fontWeight.normal');
  letter-spacing: -0.2px;
  line-height: 0.875rem;
}

@mixin small-input-filled {
  font-family: var(--font-family-secondary);
  color: var(--util__small-input-filled__color, var(--base-content-low));
  font-size: theme('fontSize.12');
  font-weight: theme('fontWeight.normal');
  letter-spacing: -0.2px;
  line-height: 0.875rem;
}

@mixin input-label {
  color: var(--base-content-low);
  font-family: var(--font-family-primary);
  font-size: theme('fontSize.14');
  font-weight: theme('fontWeight.bold');
  line-height: theme('lineHeight.4');
  padding: 0;
}
