@use './esp' as t;
@use './utils' as utils;

@mixin cos-theme-ppm {
  @include t.cos-theme-esp-font; // Take base from ESP+ light theme
  @include t.cos-theme-esp-base; // Take base from ESP+ light theme
  @include cos-theme-ppm-base; // Apply changed to base
  @include utils.cos-theme-rgb-variables; // Set RGB variables
  @include cos-theme-ppm-overrides;
}

@mixin cos-theme-ppm-classes {
  @include utils.cos-theme-classes('ppm');
}

@mixin cos-theme-ppm-base {
  // Primary -- Green
  --rgb-primary-100: 244 250 235; // #F4FAEB
  --rgb-primary-200: 208 231 167; // #D0E7A7
  --rgb-primary-300: 180 217 115; // #B4D973
  --rgb-primary-400: 161 207 79; // #A1CF4F
  --rgb-primary-500: 151 202 61; // #97CA3D
  --rgb-primary-600: 139 188 51; // #8BBC33
  --rgb-primary-700: 112 152 42; // #70982A
  --rgb-primary-800: 71 96 26; // #47601A
  --rgb-primary-900: 15 20 5; // #0F1405
  --rgb-primary-content-low: var(--rgb-primary-600);
  --rgb-primary-content-high: 255 255 255; // #FFFFFF

  // Secondary -- Blue
  --rgb-secondary-100: 229 244 255; // #E5F4FF
  --rgb-secondary-200: 97 184 255; // #61B8FF
  --rgb-secondary-300: 0 138 250; // #008AFA
  --rgb-secondary-400: 0 101 184; // #0065B8
  --rgb-secondary-500: 0 82 149; // #005295
  --rgb-secondary-600: 0 76 138; // #004C8A
  --rgb-secondary-700: 0 62 112; // #003E70
  --rgb-secondary-800: 0 42 77; // #002A4D
  --rgb-secondary-900: 0 14 26; // #000E1A
  --rgb-secondary-content-low: var(--rgb-secondary-600);
  --rgb-secondary-content-high: 255 255 255; // #FFFFFF

  // Accent -- Same as Primary
  --rgb-accent-100: var(--rgb-primary-100);
  --rgb-accent-200: var(--rgb-primary-200);
  --rgb-accent-300: var(--rgb-primary-300);
  --rgb-accent-400: var(--rgb-primary-400);
  --rgb-accent-500: var(--rgb-primary-500);
  --rgb-accent-600: var(--rgb-primary-600);
  --rgb-accent-700: var(--rgb-primary-700);
  --rgb-accent-800: var(--rgb-primary-800);
  --rgb-accent-900: var(--rgb-primary-900);
  --rgb-accent-content-low: var(--rgb-primary-content-low);
  --rgb-accent-content-high: var(--rgb-primary-content-high);
}

@mixin cos-theme-ppm-overrides {
  /* Global Header */
  --global-header__bg: var(--base-100);
  --global-header-nav__bg: var(--base-100);
  --global-header-nav__color: var(--base-600);
  --global-header-nav__hover__bg: var(--base-100);
  --global-header-nav__hover__color: var(--accent-500);

  /* Pills */
  --pill__outline__primary__color: var(--primary-800);
  --pill__outline__accent__color: var(--accent-800);
}

/* Basic colors for experiments */

// // GREEN
// --rgb-green-100: 244 250 235; // #F4FAEB
// --rgb-green-200: 208 231 167; // #D0E7A7
// --rgb-green-300: 180 217 115; // #B4D973
// --rgb-green-400: 161 207 79; // #A1CF4F
// --rgb-green-500: 151 202 61; // #97CA3D
// --rgb-green-600: 139 188 51; // #8BBC33
// --rgb-green-700: 112 152 42; // #70982A
// --rgb-green-800: 71 96 26; // #47601A
// --rgb-green-900: 15 20 5; // #0F1405
// --rgb-green-content-low: var(--rgb-green-600);
// --rgb-green-content-high: 255 255 255; // #FFFFFF

// // BLACK
// --rgb-black-100: 242 242 242; // #F2F2F2
// --rgb-black-200: 161 161 161; // #A1A1A1
// --rgb-black-300: 99 99 99; // #636363
// --rgb-black-400: 59 59 59; // #3B3B3B
// --rgb-black-500: 38 38 38; // #262626
// --rgb-black-600: 36 36 36; // #242424
// --rgb-black-700: 31 31 31; // #1F1F1F
// --rgb-black-800: 23 23 23; // #171717
// --rgb-black-900: 13 13 13; // #0D0D0D
// --rgb-black-content-low: var(--rgb-black-600);
// --rgb-black-content-high: 255 255 255; // #FFFFFF

// // BLUE
// --rgb-blue-100: 229 244 255; // #E5F4FF
// --rgb-blue-200: 97 184 255; // #61B8FF
// --rgb-blue-300: 0 138 250; // #008AFA
// --rgb-blue-400: 0 101 184; // #0065B8
// --rgb-blue-500: 0 82 149; // #005295
// --rgb-blue-600: 0 76 138; // #004C8A
// --rgb-blue-700: 0 62 112; // #003E70
// --rgb-blue-800: 0 42 77; // #002A4D
// --rgb-blue-900: 0 14 26; // #000E1A
// --rgb-blue-content-low: var(--rgb-blue-600);
// --rgb-blue-content-high: 255 255 255; // #FFFFFF
