/* ------------------------------------*\
    #SCREEN-WIDTH
\*------------------------------------ */

// This mixin applies styles for viewports wider than or equal to the specified screen size.
// E.g. @include wider-than-or-equal((theme('screens.lg')) targets 1024px and above.
@mixin wider-than-or-equal($screen-size) {
  @if $screen-size {
    @media (min-width: $screen-size) {
      @content;
    }
  } @else {
    @warn "[@mixin wider-than-or-equal]: "#{$screen-size}" has not been declared as a breakpoint.";
  }
}

// This mixin applies styles for viewports narrower than (but not equal to) the specified screen size.
// E.g. @include narrower-than((theme('screens.lg')) targets 1023px and below.
@mixin narrower-than($screen-size) {
  @if $screen-size {
    @media (max-width: calc($screen-size - 1px)) {
      @content;
    }
  } @else {
    @warn "[@mixin narrower-than]: "#{$screen-size}" has not been declared as a breakpoint.";
  }
}

// This mixin applies styles for viewports wider than or equal to the first screen size and narrower than (but not equal to) the second screen size.
// E.g. @include in-between((theme('screens.md'), theme('screens.lg')) targets 600px to 1023px.
@mixin in-between($screen-size-min, $screen-size-max) {
  @if $screen-size-min and $screen-size-max {
    @media (min-width: $screen-size-min) and (max-width: calc($screen-size-max - 1px)) {
      @content;
    }
  } @else {
    @warn "[@mixin in-between]: "#{$screen-size-max}" or "#{$screen-size-min}" has not been declared as a breakpoint.";
  }
}
