@use '../settings' as s;

// This looks like a comment, but it's not. It enables grid autoplace support for IE11.
/* autoprefixer grid: autoplace */

@mixin grid-layout() {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: auto;
  grid-gap: theme('spacing.4');
  width: 100%;
  padding: 0 theme('spacing.4');
  margin: 0 auto;

  @content;
}

// Grid styles are repeated seemingly unnecessarily to make the autoplace work properly for IE11.
.grid-layout-1 {
  @include grid-layout() {
    @include s.wider-than-or-equal(theme('screens.md')) {
      grid-template-columns: repeat(8, 1fr);
    }

    @include s.wider-than-or-equal(theme('screens.lg')) {
      grid-template-columns: repeat(12, 1fr);
      max-width: theme('screens.2xl');
    }
  }
}

.responsive-grid-layout {
  display: flex;
  flex-direction: column;
  gap: theme('spacing.4');
  padding: 0;

  @include s.wider-than-or-equal(theme('screens.lg')) {
    @include grid-layout;
    grid-template-columns: repeat(12, 1fr);
    max-width: theme('screens.2xl');
    padding: 0;
  }
}

.col-1 {
  @apply col-span-1 md:col-span-2 lg:col-span-3;
}
.col-2 {
  @apply col-span-2 md:col-span-4 lg:col-span-6;
}
.col-3 {
  @apply col-span-3 md:col-span-6 lg:col-span-9;
}
.col-4 {
  @apply col-span-4 md:col-span-8 lg:col-span-12;
}
