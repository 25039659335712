@use 'cosmos/scss/theme-styles/esp';
@use './base';
@use '@cosmos/scss/global';
@use 'tailwindcss/components';
@use 'tailwindcss/utilities';

body {
  margin: 0;
  background-color: theme('colors.base.200');
}
