@use '../settings' as s;

* {
  box-sizing: border-box;
  font-family: inherit;
}

html {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
}

body {
  @apply text-body-sm;
  color: var(--base-content-low);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: theme('spacing.4');
}

p {
  margin-top: 0;
  margin-bottom: theme('spacing.4');
}

a,
button {
  cursor: pointer;
}

a {
  color: var(--secondary-500);
  text-decoration: none;
  &:focus-visible {
    border-radius: theme('borderRadius.DEFAULT');
    @include s.cos-focus;
  }
}

fieldset {
  margin: 0;
  padding: 0;
  border: none;
}
