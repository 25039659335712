@use '@cosmos/scss/settings' as s;

.cos-attribute-tag {
  font-size: theme('fontSize.14');
  font-weight: theme('fontWeight.semibold');
  color: var(--attribute-tag__color, var(--primary-500));

  &[data-cos-size='sm'] {
    font-size: theme('fontSize.11');
  }
}
