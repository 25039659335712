%cos-accessibly-hidden,
.cdk-visually-hidden,
.cos-accessibly-hidden {
  position: absolute !important;
  clip: rect(1px, 1px, 1px, 1px);
  padding: 0 !important;
  margin: -0.0625rem !important;
  border: 0 !important;
  height: 0.0625rem !important;
  width: 0.0625rem !important;
  overflow: hidden;
  white-space: nowrap;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  left: 0;
}

.cos-hidden {
  display: none !important;
}
