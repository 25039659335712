@use './typography' as t;
@use '../functions' as f;

/// @example
///   .selector {
///     @include appearance(none);
///   }
@mixin appearance($value) {
  -webkit-appearance: $value;
  -moz-appearance: $value;
  appearance: $value;
}

@mixin cos-focus {
  --default-box-shadow: 0 0 0 0.0625rem #0f8bff,
    0rem 0rem 0.1875rem 0.125rem rgba(77, 156, 230, 0.35);

  box-shadow: var(--util__focus__box-shadow, var(--default-box-shadow));
  outline-color: var(--util__focus__outline-color, rgb(15 139 255 / 80%));
}

@mixin cos-focus-input {
  outline: none;
  // resets Chrome's default focus styles
  outline-style: none;
  border-color: transparent;
  -webkit-appearance: none;

  @include cos-focus;
}

@mixin cos-focus-inset {
  --default-box-shadow: inset 0 0 0 0.0625rem #0f8bff;
  box-shadow: var(--util__focus-inset__box-shadow, var(--default-box-shadow));
  outline-color: var(--util__focus-inset__outline-color, #0f8bff);
}

@mixin cos-focus-outset {
  --default-box-shadow: 0 0 0 0.0625rem #0f8bff;
  box-shadow: var(--util__focus-outset__box-shadow, var(--default-box-shadow));
  outline-color: var(--util__focus-outset__outline-color, #0f8bff);
}

@mixin cos-badge {
  position: absolute;
  display: block;
  top: -0.4375rem;
  right: -0.8125rem;
  width: 1.25rem;
  height: 1.25rem;
  border-radius: theme('borderRadius.round');
  @apply text-body-xs--light;
  font-weight: theme('fontWeight.semibold');
  background-color: var(--util__badge__bg, var(--warning-500));
  border: 2px solid
    var(--util__badge__border-color, var(--warning-content-high));
  color: var(--util__badge__color, var(--warning-content-low));

  &:empty {
    display: none;
  }
}

@mixin limit-content-to-lines($lines) {
  display: -webkit-box;
  -webkit-line-clamp: $lines;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
