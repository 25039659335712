@use './esp' as t;
@use './utils' as utils;

/*
  Storefront has It's own system for themes that overrides ESP+ styles direcly.
*/

@mixin cos-theme-storefront {
  @include cos-theme-storefront-font;
  @include t.cos-theme-esp-base; // Take base from ESP+ light theme
  @include utils.cos-theme-rgb-variables; // Set RGB variables
  @include cos-theme-storefront-overrides; // Take overrides from ESP+ light theme
}

@mixin cos-theme-storefront-classes {
  @include utils.cos-theme-classes('storefront');
}

@mixin cos-theme-storefront-font {
  /* 
    Those variables must be overriten by websites/stores templates. 
    We have it here just as a fallback in case it doesn't happen.

    Since It's just a fallback, we don't load any extra font. We just rely on good-enough device fonts.
 */

  $fallback-fonts:
    -apple-system,
    BlinkMacSystemFont,
    Segoe UI,
    Roboto,
    Helvetica,
    Arial,
    sans-serif;

  --font-family-primary: #{$fallback-fonts};
  --font-family-secondary: #{$fallback-fonts};
  --font-family-tertiary: #{$fallback-fonts};
}

@mixin cos-theme-storefront-overrides {
  /*
    None of the websites templates properly overrides this,
    so it ended up as primary no matter the tamplate color. 
  */
  --basic-stepper-progress__border-color: transparent;
}
