@use '../settings' as s;

.cdk-overlay-pane.dropdown-default-styling {
  background-color: var(--base-100);
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1);
  overflow-y: auto;
}

.cos-dropdown-item {
  &:hover,
  &:focus {
    background-color: var(--base-200);
    cursor: pointer;
  }
}
