@function get-pseudo-menu-position($positionY) {
  @if $positionY == 'above' {
    @return 'before';
  } @else if $positionY == 'below' {
    @return 'after';
  } @else {
    @error "Unknown position #{$positionY}.";
  }
}

/*
  This mixin applies an arrow in the mat menu panel.
  If the <cos-menu> has yPosition="above", means that the panel will be rendered
  above the element, and we should display the arrow pointer `before` the element.

  If the <cos-menu> has yPosition="below", means that the panel will be rendered
  below the element, and we should display the arrow pointer `after` the element.
*/
@mixin cos-menu-pointer($positionY: 'below') {
  position: relative;
  &::#{get-pseudo-menu-position($positionY)} {
    position: absolute;
    z-index: 1001;
    content: '';
    left: 50%;
    height: 0;
    width: 0;
    pointer-events: none;
    border: solid transparent;
    border-width: 8px;
    margin-left: calc(-1 * theme('spacing.2'));
    background: transparent;
    filter: drop-shadow(0px -1px 4px rgba(0, 0, 0, 0.1));
    clip-path: inset(-10px -10px 0 -10px);

    @if $positionY == 'above' {
      bottom: 100%;
      border-top-color: var(--base-100);
    } @else if $positionY == 'below' {
      top: 100%;
      border-bottom-color: var(--base-100);
    }
  }
}
