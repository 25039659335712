.cos-icon-quickbooks {
  min-width: 1.25rem;
  min-height: 1.25rem;
  display: inline-block;
  background-image: url('../../assets/images/icons/quickbooks.svg');
  background-repeat: no-repeat;
  background-origin: border-box;
}

.cos-icon-trophy {
  min-width: 1.688rem;
  min-height: 1.5rem;
  display: inline-block;
  background-image: url('../../assets/images/icons/trophy.svg');
  background-repeat: no-repeat;
  background-origin: border-box;
}
