:root {
  --ql-tooltip-link: 'Enter link:';
  --ql-tooltip-video: 'Enter video:';
  --ql-tooltip-link-save-btn: 'Save';
  --ql-tooltip-size-small: 'Small';
  --ql-tooltip-size-normal: 'Normal';
  --ql-tooltip-size-large: 'Large';
  --ql-tooltip-size-huge: 'Huge';
  --ql-tooltip-size-heading: 'Heading';
}

$size-values: (
  'small': var(--ql-tooltip-size-small),
  'large': var(--ql-tooltip-size-large),
  'huge': var(--ql-tooltip-size-huge),
);

.ql-snow {
  .ql-tooltip {
    &[data-mode='link'] {
      &:before {
        content: var(--ql-tooltip-link) !important;
      }
    }

    &[data-mode='video'] {
      &:before {
        content: var(--ql-tooltip-video) !important;
      }
    }

    &.ql-editing {
      a {
        &.ql-action {
          &:after {
            content: var(--ql-tooltip-link-save-btn) !important;
          }
        }
      }
    }
  }

  .ql-picker {
    &.ql-size {
      .ql-picker-label,
      .ql-picker-item {
        &:before {
          content: var(--ql-tooltip-size-normal) !important;
        }

        @each $key, $value in $size-values {
          &[data-value='#{$key}'] {
            &:before {
              content: $value !important;
            }
          }
        }
      }
    }

    &.ql-header {
      width: 6.5rem !important;

      .ql-picker-label,
      .ql-picker-item {
        &:before {
          content: var(--ql-tooltip-size-normal) !important;
        }

        @for $i from 1 through 6 {
          &[data-value='#{$i}'] {
            &:before {
              $suffix: ' #{$i}';
              content: var(--ql-tooltip-size-heading) $suffix !important;
            }
          }
        }
      }
    }
  }
}
