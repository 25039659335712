@use '../settings' as s;

.cos-inline-list {
  list-style: none;
  margin-top: 0;
  margin-bottom: theme('spacing.4');
  margin-left: calc(-1 * theme('spacing.4'));
  margin-right: 0;
  overflow: hidden;
  padding: 0;

  & > li {
    display: block;
    float: left;
    list-style: none;
    margin-left: theme('spacing.4');

    & > * {
      display: block;
    }
  }
}

.cos-list-no-style {
  list-style: none;
}
