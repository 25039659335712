@use '../settings' as s;

// @ Typography

html {
  line-height: 1.15; /* 1 */
  -webkit-text-size-adjust: 100%; /* 2 */
}

html,
body {
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
}

// Headings
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-weight: theme('fontWeight.normal');
}

h1,
.h1 {
  font-size: 1.5rem;
}

h2,
.h2 {
  font-size: 1.25rem;
}

h3,
.h3 {
  font-size: 1rem;
}

h4,
.h4 {
  font-size: 0.937rem;
}

h5,
.h5 {
  font-size: 0.812rem;
}

h6,
.h6 {
  font-size: 0.75rem;
}

// Links
a {
  text-decoration: none;

  &:not(
      .cos-button,
      .cos-raised-button,
      .cos-flat-button,
      .cos-stroked-button,
      .mat-button,
      .mat-raised-button,
      .mat-flat-button,
      .mat-stroked-button,
      .mat-icon-button,
      .mat-fab,
      .mat-mini-fab,
      .mat-mdc-tab-link
    ) {
    &:hover,
    &:active {
      text-decoration: underline;
    }
  }
}

// Abbr
abbr {
  cursor: help;
  border-bottom: 1px dotted rgba(0, 0, 0, 0.54);
}

// Address
address {
  font-style: normal;
}

// Blockquote
blockquote {
  border-left: 3px solid rgba(0, 0, 0, 0.12);
  font-style: italic;
  margin: 1em 0;
  padding-left: theme('spacing.4');

  footer {
    font-style: normal;

    &:before {
      content: '\2014 \00A0';
    }
  }

  &.reverse {
    border-left: none;
    border-right: 3px solid rgba(0, 0, 0, 0.12);
    text-align: right;
    padding-left: 0;
    padding-right: theme('spacing.4');

    footer {
      &:before {
        content: '';
      }

      &:after {
        content: '\2014 \00A0';
      }
    }
  }
}

// Code
code {
  font-family: 'Monaco', 'Menlo', 'Consolas', 'Ubuntu Mono', monospace;

  &:not(.highlight) {
    color: #106cc8;
    padding: 0.125rem 0.187rem;
    border-radius: 0.125rem;
    line-height: 1.7;
  }
}

// Definition lists
dl {
  dt {
    font-weight: theme('fontWeight.bold');
  }

  dd {
    margin: theme('spacing.1') 0 theme('spacing.4');
  }
}

// Mark
mark {
  background: #f7f49a;
}

p {
  margin-top: 0;
}

// Pre
pre {
  line-height: 1.6;
  margin: theme('spacing.2') theme('spacing.4');
  white-space: pre-wrap;
}

// Small
small {
  font-size: 80%;
}
