@use './admin' as t;
@use './esp' as esp;
@use './utils' as utils;

/* TODO(caio): Check if we really need a dark theme for this */
@mixin cos-theme-admin-dark {
  @include esp.cos-theme-esp-font; // Take font from ESP+ light theme
  @include t.cos-theme-admin-base; // Take base from Admin light theme
  @include cos-theme-admin-dark-base; // Apply changed to base
  @include utils.cos-theme-rgb-variables; // Set RGB variables
  @include t.cos-theme-admin-overrides; // Take overrides from Admin light theme
  @include cos-theme-admin-dark-overrides;
}

@mixin cos-theme-admin-dark-classes {
  @include utils.cos-theme-classes('admin-dark');
}

@mixin cos-theme-admin-dark-base {
  --rgb-base-100: 2 6 23; // #020617
  --rgb-base-200: 15 23 42; // #0f172a
  --rgb-base-300: 30 41 59; // #1e293b
  --rgb-base-400: 51 65 85; // #334155
  --rgb-base-500: 71 85 105; // #475569
  --rgb-base-600: 100 116 139; // #64748b
  --rgb-base-content-low: 255 255 255; // white
  --rgb-base-content-high: 255 255 255; // white
}

@mixin cos-theme-admin-dark-overrides {
  --sidebar__bg: var(--base-100);
}
