@use '../settings' as s;

p {
  margin-bottom: theme('spacing.4');
}

.content-section {
  h2 {
    margin-top: 0;
    margin-bottom: theme('spacing.1');
  }
}

// TODO(caio): Find a styling solution for buttons that should look like a link
.view-all-link {
  display: inline-block;
  height: fit-content;
  font-size: theme('fontSize.12');
  font-weight: theme('fontWeight.semibold');
  line-height: 1.165;
  padding: 0;
  background: transparent;
  border: none;
  color: theme('colors.secondary.500');

  &:hover {
    text-decoration: underline;
  }

  &:focus-visible {
    @include s.cos-focus;
  }
}

.search-header {
  > h1 {
    margin-bottom: theme('spacing.2');
  }
}
