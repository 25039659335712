.cos-container {
  padding: theme('spacing.4');

  &--pearl {
    background: var(--base-200);
  }
}

.grid {
  @for $i from 1 through 12 {
    // It seems there is no alternative in Tailwind for repeat 'auto'
    &-cols-#{$i}-auto {
      grid-template-columns: repeat($i, auto);
    }
    &-rows-#{$i}-auto {
      grid-template-rows: repeat($i, auto);
    }
  }

  @for $i from 4 through 64 {
    @if $i % 4 == 0 {
      &-gap-#{$i} {
        grid-gap: #{$i}px;
      }
    }
  }
}

.img-responsive {
  width: 100%;
  height: auto;
}
