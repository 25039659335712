@use './utils' as utils;

@mixin cos-theme-esp {
  @include cos-theme-esp-font;
  @include cos-theme-esp-base;
  @include utils.cos-theme-rgb-variables;
  @include cos-theme-esp-overrides;
}

@mixin cos-theme-esp-classes {
  @include utils.cos-theme-classes('esp');
}

/* 
  This mixin is only suppose to define the font variables, not other typography-related variables.
  For that use case, please use cos-theme-esp-base or cos-theme-esp-overrides instead.
*/
@mixin cos-theme-esp-font {
  --font-family-primary: Inter, sans-serif;
  --font-family-secondary: Inter, sans-serif;
  --font-family-tertiary: Inter, serif;
}

@mixin cos-theme-esp-base {
  // Primary
  --rgb-primary-100: 229 243 255; // #E5F3FF
  --rgb-primary-200: 122 191 255; // #7ABFFF
  --rgb-primary-300: 46 154 255; // #2E9AFF
  --rgb-primary-400: 0 126 245; // #007EF5
  --rgb-primary-500: 0 113 219; // #0071DB
  --rgb-primary-600: 0 103 199; // #0067C7
  --rgb-primary-700: 0 84 163; // #0054A3
  --rgb-primary-800: 0 53 102; // #003566
  --rgb-primary-900: 0 13 26; // #000D1A
  --rgb-primary-content-low: var(--rgb-primary-600);
  --rgb-primary-content-high: 255 255 255; // #FFFFFF

  // Secondary
  --rgb-secondary-100: 234 243 251; // #EAF3FB
  --rgb-secondary-200: 116 178 226; // #74B2E2
  --rgb-secondary-300: 41 127 194; // #297FC2
  --rgb-secondary-400: 28 89 135; // #1C5987
  --rgb-secondary-500: 22 69 105; // #164569
  --rgb-secondary-600: 21 63 97; // #153F61
  --rgb-secondary-700: 17 52 80; // #113450
  --rgb-secondary-800: 12 36 55; // #0C2437
  --rgb-secondary-900: 4 14 21; // #040E15
  --rgb-secondary-content-low: var(--rgb-secondary-600);
  --rgb-secondary-content-high: 255 255 255; // #FFFFFF

  // Accent
  --rgb-accent-100: 231 253 248; // #E7FDF8
  --rgb-accent-200: 103 244 213; // #67F4D5
  --rgb-accent-300: 17 228 181; // #11E4B5
  --rgb-accent-400: 12 166 132; // #0CA684
  --rgb-accent-500: 10 133 106; // #0A856A
  --rgb-accent-600: 9 123 98; // #097B62
  --rgb-accent-700: 7 100 79; // #07644F
  --rgb-accent-800: 5 66 53; // #054235
  --rgb-accent-900: 2 24 19; // #021813
  --rgb-accent-content-low: var(--rgb-accent-600);
  --rgb-accent-content-high: 255 255 255; // #FFFFFF

  // Base
  --rgb-base-100: 255 255 255; // #FFFFFF
  --rgb-base-200: 247 247 248; // #F7F7F8
  --rgb-base-300: 231 232 233; // #E7E8E9
  --rgb-base-400: 170 173 177; // #AAADB1
  --rgb-base-500: 145 148 156; // #91949C
  --rgb-base-600: 111 114 123; // #6F727B
  --rgb-base-700: 77 80 86; // #4D5056
  --rgb-base-800: 46 47 51; // #2E2F33
  --rgb-base-900: 12 12 13; // #0C0C0D
  --rgb-base-content-low: 30 31 32; // #1E1F20
  --rgb-base-content-high: 255 255 255; // #FFFFFF

  // Success
  --rgb-success-100: 231 253 248; // #E7FDF8
  --rgb-success-200: 103 244 213; // #67F4D5
  --rgb-success-300: 17 228 181; // #11E4B5
  --rgb-success-400: 12 166 132; // #0CA684
  --rgb-success-500: 10 133 106; // #0A856A
  --rgb-success-600: 9 123 98; // #097B62
  --rgb-success-700: 7 100 79; // #07644F
  --rgb-success-800: 5 66 53; // #054235
  --rgb-success-900: 2 24 19; // #021813
  --rgb-success-content-low: var(--rgb-success-600);
  --rgb-success-content-high: 255 255 255; // #FFFFFF

  // Warning
  --rgb-warning-100: 254 248 230; // #FEF8E6
  --rgb-warning-200: 251 224 152; // #FBE098
  --rgb-warning-300: 249 207 98; // #F9CF62
  --rgb-warning-400: 248 195 58; // #F8C33A
  --rgb-warning-500: 247 189 38; // #F7BD26
  --rgb-warning-600: 246 182 14; // #F6B60E
  --rgb-warning-700: 202 148 7; // #CA9407
  --rgb-warning-800: 123 90 5; // #7B5A05
  --rgb-warning-900: 25 18 1; // #191201
  --rgb-warning-content-low: 30 31 32; // #1E1F20
  --rgb-warning-content-high: 255 255 255; // #FFFFFF

  // Danger
  --rgb-danger-100: 251 237 233; // #FBEDE9
  --rgb-danger-200: 237 167 151; // #EDA797
  --rgb-danger-300: 226 115 90; // #E2735A
  --rgb-danger-400: 219 82 51; // #DB5233
  --rgb-danger-500: 208 68 37; // #D04425
  --rgb-danger-600: 191 62 34; // #BF3E22
  --rgb-danger-700: 152 50 27; // #98321B
  --rgb-danger-800: 95 31 17; // #5F1F11
  --rgb-danger-900: 22 7 4; // #160704
  --rgb-danger-content-low: var(--rgb-danger-600);
  --rgb-danger-content-high: 255 255 255; // #FFFFFF

  /* Utilities - Brand Gradient */
  --util__gradient__bg: linear-gradient(
    -90deg,
    rgba(16, 184, 156, 50%) 0%,
    rgb(0, 180, 219) 56%,
    rgba(11, 115, 233, 50%) 100%
  );
}

@mixin cos-theme-esp-overrides {
  /* NOTE: 
    If esp gets adopted as ESP+ theme those colors must be changed in the source and not as a override
  */
  --global-header-nav__hover__bg: var(--secondary-400);
}
