@use '../settings' as s;

$input-large-spacing: 0.6875rem;

.has-error,
.cos-form-field-invalid .cos-input {
  border: 1px solid var(--danger-500);
}

.error,
.cos-error,
.cos-warning {
  margin-top: theme('spacing.1');
  @apply text-body-xs--light;
  &::before {
    content: '';
    display: inline-block;
    vertical-align: top;
    margin-right: theme('spacing[0.5]');
    height: 0.8125rem;
    width: 0.75rem;
    mask-image: url("data:image/svg+xml,%3Csvg aria-hidden='true' focusable='false' data-prefix='fas' data-icon='exclamation-circle' class='svg-inline--fa fa-exclamation-circle fa-w-16' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath d='M504 256c0 136.997-111.043 248-248 248S8 392.997 8 256C8 119.083 119.043 8 256 8s248 111.083 248 248zm-248 50c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z'%3E%3C/path%3E%3C/svg%3E");
    mask-repeat: no-repeat;
    mask-position: center;
  }
}

.error,
.cos-error {
  color: var(--danger-500);
  &::before {
    background-color: var(--danger-500);
  }
}

.cos-error {
  &::before {
    margin-right: theme('spacing.2');
  }
}

// styles for warning validation state
.cos-warning {
  color: var(--primary-900);
  &::before {
    background-color: var(--warning-600);
  }
}

.cos-form-field-invalid[class*='cos-warning'] {
  .cos-input:not(:focus-visible) {
    border-color: var(--warning-600);
  }

  .cos-error {
    color: var(--primary-900);
    &::before {
      background-color: var(--warning-600);
    }
  }
}

.cos-hint {
  margin-top: theme('spacing.1');
  @apply text-body-xs--light;
}

.cos-form-row {
  margin-bottom: theme('spacing.4');
  align-self: start;
}

.cos-form-label {
  display: block;
  margin-bottom: theme('spacing.2');
  @include s.input-label();
}

.cos-radio-group {
  display: inline-flex;
  flex-direction: column;
}

// adjust padding for prefix icons
.cos-form-field-prefix ~ .cos-input {
  padding-left: theme('spacing.8');
}
.cos-form-field-suffix ~ .cos-input {
  padding-right: theme('spacing.8');
}

.cos-input-clearable {
  display: flex;
  .cos-form-field-suffix {
    height: 2rem;
    width: 2rem;
    bottom: 0 !important;
    right: -2rem !important;
  }
  .cos-input {
    padding-right: 1.875rem !important;
  }
}

/* stylelint-disable */
.cos-input,
.cos-select {
  padding: theme('spacing.2') theme('spacing.3');
  display: block;
  border: 1px solid var(--input__border-color, var(--base-500));
  border-radius: theme('borderRadius.DEFAULT');
  background-color: var(--input__bg, var(--base-100));
  width: 100%;
  box-shadow: none;
  @include s.medium-input-filled();

  &::placeholder,
  &-placeholder {
    @include s.medium-input-placeholder();
  }

  &:disabled,
  &.cos-select-disabled {
    // border-color: var(--base-500);
    border-color: var(--input__disabled__border-color, var(--base-400));
    // background-color: var(--base-400);
    background-color: var(--input__disabled__bg, var(--base-300));
    // color: var(--base-600);
    color: var(--input__disabled__color, var(--base-400));

    &::placeholder {
      // color: var(--base-600);
      color: var(--input__disabled__placeholder__color, var(--base-400));
    }
  }

  &.has-error {
    border: 1px solid var(--danger-500);
  }

  &:focus-visible {
    @include s.cos-focus-input();
  }

  &-panel {
    margin-left: -0.0625rem !important;
    margin: 0 0.9375rem;
    background-color: var(--base-100);
    max-width: 0rem;
    box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.1) !important;
  }

  &--small {
    padding: theme('spacing.1') theme('spacing[1.5]');
    @include s.small-input-filled();
    &::placeholder {
      @include s.small-input-placeholder();
    }
  }

  &--large {
    padding: $input-large-spacing theme('spacing.3');
    @include s.large-input-filled();
    &::placeholder {
      @include s.large-input-placeholder();
    }
  }
}

select.cos-input,
.cos-input-with-arrow {
  @include s.appearance(none);
  --default-background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%230071db%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E');
  background-image: var(
    --input__arrow__bg-image,
    var(--default-background-image)
  );
  background-repeat: no-repeat, repeat;
  background-position:
    right theme('spacing.3') top 50%,
    0 0;
  background-size:
    10px auto,
    100%;
  padding-right: 1.875rem;
}

/* stylelint-disable */
select.cos-input,
.mat-mdc-select {
  min-width: 10rem;

  &::-ms-expand {
    display: none;
  }

  option {
    font-weight: theme('fontWeight.normal');
  }

  &:disabled {
    color: var(--base-600);
    background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22graytext%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E');
  }
}

.mat-mdc-select {
  padding: 0;

  .mat-mdc-select-trigger {
    padding: theme('spacing.2') theme('spacing.3');
    > .mat-mdc-select-value {
      line-height: theme('lineHeight.4');

      > .mat-mdc-select-value-text {
        display: block;
        max-width: 87%;
      }
    }
  }

  .mat-mdc-select-arrow {
    width: 1.125rem;
    height: 1.125rem;
    margin: 0;
    border: none;
    background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%230071db%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E');
    background-repeat: no-repeat, repeat;
    background-position:
      right 0 top 50%,
      0 0;
    background-size:
      10px auto,
      100%;
  }

  .mat-mdc-select-arrow-wrapper {
    color: transparent;
    height: 1rem;
  }

  &.mat-mdc-select-disabled {
    color: var(--base-600);

    .mat-mdc-select-arrow {
      background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22graytext%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E');
    }
  }
}

/* Hide input buttons - Chrome, Safari, Edge, Opera */
input.hide-spinner::-webkit-outer-spin-button,
input.hide-spinner::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Hide input buttons Firefox */
input[type='number'].hide-spinner {
  -moz-appearance: textfield !important;
}

.date-picker {
  max-width: 16.75rem;
}

textarea.cos-input {
  box-shadow: none;
  resize: none;
}

input[type='color'] {
  appearance: none;
  background: var(--base-100);
  height: 2rem;
  width: 3.125rem;
  padding: 0;

  &.cos-input--small {
    height: 1.375rem;
  }

  &.cos-input--large {
    height: 2.5rem;
  }
}

input[type='color']::-webkit-color-swatch-wrapper {
  padding: theme('spacing.1');
  overflow: hidden;
}

ngx-dropzone.cos-dropzone {
  border: 2px dashed var(--dropzone__border-color, var(--base-400));
  background: var(--dropzone__bg, var(--base-100));
}

.cos-dropzone-display {
  display: flex;
  flex-direction: column;

  i {
    font-size: theme('fontSize.24');
    color: var(--dropzone__icon__color, var(--primary-500));
    margin-bottom: theme('spacing.3');
  }
  .cos-dropzone-upload {
    background: var(--dropzone__button__bg, var(--accent-500));
    color: var(--dropzone__button__color, var(--accent-content-high));
    padding: theme('spacing.1');
    border-radius: 5px;
    font-size: theme('fontSize.12');
  }

  .cos-dropzone-label {
    color: var(--dropzone__label__color, var(--base-600));
  }
}

.cos-form-field-required-marker {
  color: var(--primary-500);
}

input[type='search'] {
  appearance: none;
}

/* clears the 'X' from Internet Explorer */
input[type='search']::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}
input[type='search']::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

/* clears the 'eye icon' from Internet Explorer */
input[type='password']::-ms-reveal {
  display: none;
}

/* clears the 'X' from Chrome */
input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  display: none;
}

// cos-addres-typeahead directive dropdown
.pac-container {
  margin-top: theme('spacing.2');

  width: 100%;
  border-radius: 4px;
  box-shadow: 0px 1px 10px 0px rgb(0 0 0 / 10%);
  background: theme('colors.base.100');

  font-family:
    Work Sans,
    sans-serif;
  font-size: theme('fontSize.12');

  .pac-item {
    border: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    line-height: 3rem;
    height: 3rem;
    padding: 0 theme('spacing.4');
    text-align: left;
    text-decoration: none;
    max-width: 100%;
    position: relative;
    cursor: pointer;
    outline: none;
    display: flex;
    flex-direction: row;
    max-width: 100%;
    box-sizing: border-box;
    align-items: center;
    -webkit-tap-highlight-color: transparent;

    .pac-icon-marker {
      min-width: 0.9375rem;
    }

    &:hover {
      background: theme('colors.base.200');
    }
  }
  .pac-item-selected {
    background: theme('colors.base.200');
    .pac-icon-marker {
      background-position: -1px -161px;
    }
  }
}

.select-all-label {
  display: block;
  @include s.input-label();
}
