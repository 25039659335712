@use '../settings' as s;

input[type='range'] {
  -webkit-appearance: none;
  background-color: transparent;
}

input[type='range']::-webkit-slider-runnable-track {
  height: 0.5rem;
  background: #dedfe1;
  border: none;
  border-radius: 5px;
}

input[type='range']::-moz-range-track {
  height: 0.5rem;
  background: #dedfe1;
  border: none;
  border-radius: 5px;
}

input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none;
  border: none;
  height: 1rem;
  width: 1rem;
  border-radius: 50%;
  background: var(--success-500);
  margin-top: -0.25rem;
}

input[type='range']::-moz-range-thumb {
  border: none;
  height: 1rem;
  width: 1rem;
  border-radius: 50%;
  background: var(--success-500);
  margin-top: -0.25rem;
}

input[type='range']:focus {
  outline: none;
}
