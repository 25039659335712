@use '../settings' as s;

:root {
  --global-layout-footer-tabs-height: 0px;
  --global-layout-header-height: 0px;
}

.page-container {
  margin-top: theme('spacing.4');
}

.content-container {
  width: 100%;
  margin: 0 auto;

  @apply px-safe-offset-4;

  @include s.wider-than-or-equal(theme('screens.lg')) {
    max-width: theme('screens.2xl');
  }
}
