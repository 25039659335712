@use './esp' as t;
@use './utils' as utils;

/*
  Storefront has It's own system for themes that overrides ESP+ styles direcly.
*/

@mixin cos-theme-checkout {
  @include t.cos-theme-esp-font; // Take base from ESP+ light theme
  @include t.cos-theme-esp-base; // Take base from ESP+ light theme
  @include utils.cos-theme-rgb-variables; // Set RGB variables
  @include cos-theme-checkout-overrides; // Take overrides from ESP+ light theme
}

@mixin cos-theme-checkout-classes {
  @include utils.cos-theme-classes('checkout');
}

@mixin cos-theme-checkout-overrides {
  --basic-stepper-progress__border-color: transparent;
}
