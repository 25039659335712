$icon-font-family: 'Font Awesome 6 Free';

.mat-menu-item {
  background: transparent;
  color: rgba(0, 0, 0, 87%);
}

.mat-ripple {
  overflow: hidden;
}
