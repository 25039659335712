@media print {
  html,
  body {
    height: auto !important;
    overflow: initial !important;

    * {
      box-shadow: none !important;
    }

    div[role='menu'] {
      display: none !important;
    }
  }

  esp-app-root,
  esp-mobile-app-root,
  supplier-app-root {
    asi-go-back,
    cos-global-footer,
    cos-feature-flags-toolbar,
    cos-back-button,
    .cos-global-header {
      display: none;
    }

    #main,
    #container-1,
    #container-2,
    #container-3 {
      padding: 0 !important;
    }
  }
}
