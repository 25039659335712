@use '../settings' as s;

.border-light {
  border: theme('borders.light');
}

.border-top {
  border-top: theme('borders.light');
}

.border-right {
  border-right: theme('borders.light');
}

.border-bottom {
  border-bottom: theme('borders.light');
}

.border-left {
  border-left: theme('borders.light');
}

@include s.wider-than-or-equal(theme('screens.lg')) {
  .lg\: {
    &border-top {
      border-top: theme('borders.light');
    }

    &border-right {
      border-right: theme('borders.light');
    }

    &border-bottom {
      border-bottom: theme('borders.light');
    }

    &border-left {
      border-left: theme('borders.light');
    }
  }
}
