[hidden] {
  display: none !important;
}

a.disabled {
  pointer-events: none;
  cursor: default;
}

.ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: inline-block;
  width: 100%;
}

.whitespace-initial {
  word-break: break-all;
  white-space: initial;
}
