@mixin cos-theme-classes($theme) {
  $cos-themes: 'esp', 'esp-dark', 'supplier', 'vernon', 'aia-core', 'ppm',
    'fullypromoted', 'halo', 'storefront', 'checkout', 'admin', 'ipu';

  /* Create "theme-only" classes */
  @each $thm in $cos-themes {
    .cos-theme-#{$thm}-only {
      @if ($thm != $theme) {
        display: none;
      } @else {
        display: unset;
      }
    }
  }
}

/* Important:--rgb variables must be defined using the space-separated syntax; */
@mixin cos-theme-rgb-variables {
  // Primary
  --primary-100: rgb(var(--rgb-primary-100));
  --primary-200: rgb(var(--rgb-primary-200));
  --primary-300: rgb(var(--rgb-primary-300));
  --primary-400: rgb(var(--rgb-primary-400));
  --primary-500: rgb(var(--rgb-primary-500));
  --primary-600: rgb(var(--rgb-primary-600));
  --primary-700: rgb(var(--rgb-primary-700));
  --primary-800: rgb(var(--rgb-primary-800));
  --primary-900: rgb(var(--rgb-primary-900));
  --primary-content-low: rgb(var(--rgb-primary-content-low));
  --primary-content-high: rgb(var(--rgb-primary-content-high));

  // Secondary
  --secondary-100: rgb(var(--rgb-secondary-100));
  --secondary-200: rgb(var(--rgb-secondary-200));
  --secondary-300: rgb(var(--rgb-secondary-300));
  --secondary-400: rgb(var(--rgb-secondary-400));
  --secondary-500: rgb(var(--rgb-secondary-500));
  --secondary-600: rgb(var(--rgb-secondary-600));
  --secondary-700: rgb(var(--rgb-secondary-700));
  --secondary-800: rgb(var(--rgb-secondary-800));
  --secondary-900: rgb(var(--rgb-secondary-900));
  --secondary-content-low: rgb(var(--rgb-secondary-content-low));
  --secondary-content-high: rgb(var(--rgb-secondary-content-high));

  // Accent
  --accent-100: rgb(var(--rgb-accent-100));
  --accent-200: rgb(var(--rgb-accent-200));
  --accent-300: rgb(var(--rgb-accent-300));
  --accent-400: rgb(var(--rgb-accent-400));
  --accent-500: rgb(var(--rgb-accent-500));
  --accent-600: rgb(var(--rgb-accent-600));
  --accent-700: rgb(var(--rgb-accent-700));
  --accent-800: rgb(var(--rgb-accent-800));
  --accent-900: rgb(var(--rgb-accent-900));
  --accent-content-low: rgb(var(--rgb-accent-content-low));
  --accent-content-high: rgb(var(--rgb-accent-content-high));

  // Base
  --base-100: rgb(var(--rgb-base-100));
  --base-200: rgb(var(--rgb-base-200));
  --base-300: rgb(var(--rgb-base-300));
  --base-400: rgb(var(--rgb-base-400));
  --base-500: rgb(var(--rgb-base-500));
  --base-600: rgb(var(--rgb-base-600));
  --base-700: rgb(var(--rgb-base-700));
  --base-800: rgb(var(--rgb-base-800));
  --base-900: rgb(var(--rgb-base-900));
  --base-content-low: rgb(var(--rgb-base-content-low));
  --base-content-high: rgb(var(--rgb-base-content-high));

  // Success
  --success-100: rgb(var(--rgb-success-100));
  --success-200: rgb(var(--rgb-success-200));
  --success-300: rgb(var(--rgb-success-300));
  --success-400: rgb(var(--rgb-success-400));
  --success-500: rgb(var(--rgb-success-500));
  --success-600: rgb(var(--rgb-success-600));
  --success-700: rgb(var(--rgb-success-700));
  --success-800: rgb(var(--rgb-success-800));
  --success-900: rgb(var(--rgb-success-900));
  --success-content-low: rgb(var(--rgb-success-content-low));
  --success-content-high: rgb(var(--rgb-success-content-high));

  // Warning
  --warning-100: rgb(var(--rgb-warning-100));
  --warning-200: rgb(var(--rgb-warning-200));
  --warning-300: rgb(var(--rgb-warning-300));
  --warning-400: rgb(var(--rgb-warning-400));
  --warning-500: rgb(var(--rgb-warning-500));
  --warning-600: rgb(var(--rgb-warning-600));
  --warning-700: rgb(var(--rgb-warning-700));
  --warning-800: rgb(var(--rgb-warning-800));
  --warning-900: rgb(var(--rgb-warning-900));
  --warning-content-low: rgb(var(--rgb-warning-content-low));
  --warning-content-high: rgb(var(--rgb-warning-content-high));

  // Danger
  --danger-100: rgb(var(--rgb-danger-100));
  --danger-200: rgb(var(--rgb-danger-200));
  --danger-300: rgb(var(--rgb-danger-300));
  --danger-400: rgb(var(--rgb-danger-400));
  --danger-500: rgb(var(--rgb-danger-500));
  --danger-600: rgb(var(--rgb-danger-600));
  --danger-700: rgb(var(--rgb-danger-700));
  --danger-800: rgb(var(--rgb-danger-800));
  --danger-900: rgb(var(--rgb-danger-900));
  --danger-content-low: rgb(var(--rgb-danger-content-low));
  --danger-content-high: rgb(var(--rgb-danger-content-high));
}
